import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSW.png"

function Logistics() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap logiWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap logiOverview">
          <div className="textWrap">
            <h2>스마트 물류</h2>
            <p className="bodyText">
              인터넷의 발달로 전자상거래가 활성화됨에 따라 원자재에서 부터
              공정을 걸쳐 완제품을 소비자에 전달하기까지 신속 정확하게 물자의
              흐름을 관리할 필요성이 커지고 있습니다. 특히, 상품 배송서비스는 그
              자체로 기업의 이미지를 판단하는 하나의 척도로 자리할만큼 중요한
              위치를 차지하고 있습니다. 스마트 물류의 핵심은 물류 전체의 과정
              상의 화물의 정보, 자금흐름 등을 유연하게 연계시키고 효율, 안전,
              환경성 등을 달성하는데 있습니다. <br />
              <br />
              ELSA.Cloud SaaS<span className="tmText">TM</span>에서 데이터를
              통합 관리하고 현장의 직원들은 모바일 어플리케이션을 통해 직접
              필요한 정보를 조회, 입력 함으로써 물류 데이터의 정확성/신뢰성을
              높이고 일련의 흐름을 한눈에 확인할 수 있습니다. 물류와 자금의
              흐름관리를 통해 고효율 프로세스를 구축하고 정확한 재고 및 입출고
              관리를 해보세요.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Logistics
